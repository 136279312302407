import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import PrivateRoute from "./config/privateRoutes";

// Pages
import Login from "./pages/Login";
import PasswordReset from "./pages/PasswordReset";

// Dashboard
import Dashboard from "./pages/Dashboard";
import Subscriptions from "./pages/Dashboard/Subscriptions";
import Establishments from "./pages/Dashboard/Establishments";
import Create from "./pages/Dashboard/Establishments/Create";
import Edit from "./pages/Dashboard/Establishments/Edit";
import UsedDiscounts from "./pages/Dashboard/UsedDiscounts";

function RedirectToLogin() {
  return <Navigate to="/login" />;
}

const RootElement = () => (
  <Routes>
    <Route path="/" element={<Login />} />
    <Route path="/login" element={<Login />} />
    <Route path="/alteracao-senha/:hash" element={<PasswordReset />} />
    {/* AUTENTICADO */}
    <Route
      path="/dashboard"
      element={
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
      }
    />
    {/* ADMIN */}
    <Route
      path="/subscriptions"
      element={
        <PrivateRoute adminOnly={true}>
          <Subscriptions />
        </PrivateRoute>
      }
    />
    <Route
      path="/establishments"
      element={
        <PrivateRoute adminOnly={true}>
          <Establishments />
        </PrivateRoute>
      }
    />
    <Route
      path="/establishments/create"
      element={
        <PrivateRoute adminOnly={true}>
          <Create />
        </PrivateRoute>
      }
    />
    <Route
      path="/establishments/edit/:id"
      element={
        <PrivateRoute adminOnly={true}>
          <Edit />
        </PrivateRoute>
      }
    />

    <Route
      path="/used-discounts"
      element={
        <PrivateRoute adminOnly={true}>
          <UsedDiscounts />
        </PrivateRoute>
      }
    />
    {/* 404 */}
    <Route path="*" element={<RedirectToLogin />} />
  </Routes>
);

export default RootElement;
